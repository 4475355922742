import * as React from "react";
import { apiUrl } from "../constants";

const successTimeoutMs = 60000; // 1 minute
const errorTimeoutMs = 3000; // 3 seconds

interface Session {
  userId: null | string;
  setUserId: (userId: string) => void;
  unsetUserId: () => void;
}

export const SessionContext = React.createContext<Session>({
  userId: null,
  setUserId: () => {},
  unsetUserId: () => {},
});

interface Props {
  children: React.ReactNode;
}

export default function SessionProvider(props: Props) {
  // Define state and functions for updating it.
  const initialUserId = React.useMemo(() => localStorage.getItem("userId"), []);
  const [userId, setUserId] = React.useState(initialUserId);
  const set = React.useCallback((userId: string) => {
    setUserId(userId);
    localStorage.setItem("userId", userId);
  }, []);
  const unset = React.useCallback(() => {
    setUserId(null);
    localStorage.removeItem("userId");
  }, []);
  const session = React.useMemo(
    () => ({
      userId,
      setUserId: set,
      unsetUserId: unset,
    }),
    [userId, set, unset]
  );

  // Refresh session periodically.
  React.useEffect(() => {
    let timeoutId: undefined | NodeJS.Timer = undefined;
    const refresh = async () => {
      try {
        const response = await fetch(`${apiUrl}/session`, {
          method: "post",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        const json = await response.json();
        if (timeoutId === undefined) {
          return;
        }
        if (!response.ok) {
          throw new Error("response not ok");
        }
        const { userId } = json;
        if (userId === null) {
          unset();
        } else if (typeof userId === "string") {
          set(userId);
        } else {
          throw new Error("wrong userId type");
        }
        timeoutId = setTimeout(refresh, successTimeoutMs);
      } catch (error) {
        if (timeoutId === undefined) {
          return;
        }
        timeoutId = setTimeout(refresh, errorTimeoutMs);
      }
    };
    timeoutId = setTimeout(refresh, 0);
    return () => {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    };
  }, [set, unset]);

  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  );
}

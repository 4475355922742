import { Button } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  icon: React.ReactNode;
  name: string;
  path: string;
}

export default function FooterMenuItem(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const active = props.path === location.pathname;
  return (
    <Button
      sx={{ flexDirection: "column" }}
      color="neutral"
      variant={active ? "soft" : "plain"}
      disabled={active}
      onClick={() => navigate(props.path)}
    >
      {props.icon}
      {props.name}
    </Button>
  );
}

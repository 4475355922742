import { Box, IconButton, Typography } from "@mui/joy";
import { Close as CloseIcon } from "@mui/icons-material";
import { ChatResponse } from "../interfaces/chats";
import ChatComposer from "../components/ChatComposer";
import ChatMessages from "../components/ChatMessages";
import { useNavigate } from "react-router-dom";
import useTitle from "../hooks/useTitle";

interface Props {
  chat: ChatResponse;
}

export default function ChatInnerPage(props: Props) {
  useTitle(props.chat.name);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        bgcolor: "background.appBody",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.surface",
          p: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ flexGrow: 1 }} level="title-lg" noWrap>
          {props.chat.name}
        </Typography>
        <IconButton size="lg" onClick={() => navigate("/dashboard")}>
          <CloseIcon />
        </IconButton>
      </Box>
      <ChatMessages chat={props.chat} />
      <ChatComposer chat={props.chat} />
    </Box>
  );
}

import React from "react";

export default function useRefresh<T>(fn: () => T, ms: number): T {
  const initialValue = React.useMemo(() => fn(), [fn]);
  const [value, setValue] = React.useState<T>(initialValue);
  React.useEffect(() => {
    setValue(fn());
    const intervalId = setInterval(() => setValue(fn()), ms);
    return () => clearInterval(intervalId);
  }, [fn, ms]);
  return value;
}

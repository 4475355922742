import { useParams } from "react-router-dom";
import nullthrows from "nullthrows";
import useGetApi from "../hooks/useGetApi";
import { ChatResponse } from "../interfaces/chats";
import LoadingPage from "./LoadingPage";
import ChatInnerPage from "./ChatInnerPage";
import ChatEventsProvider from "../providers/ChatEventsProvider";
import ErrorPage from "./ErrorPage";

export default function ChatPage() {
  const params = useParams();
  const chatId = nullthrows(params.chatId);
  const chat = useGetApi<ChatResponse>(`/chats/${chatId}`);
  if (chat.status === "loading") {
    return <LoadingPage />;
  }
  if (chat.status === "error") {
    return <ErrorPage error={chat.error} />;
  }
  return (
    <ChatEventsProvider chatId={chatId}>
      <ChatInnerPage chat={chat.response} />
    </ChatEventsProvider>
  );
}

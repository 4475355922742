import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
} from "@mui/joy";
import useApi from "../hooks/useApi";
import React from "react";
import useSnackbar from "../hooks/useSnackbar";
import { nameMaxLength } from "../constants";

interface Props {
  initialName: string;
  open: boolean;
  onClose: () => void;
}

export default function NameChangeModal(props: Props) {
  const [name, setName] = React.useState(props.initialName);
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  React.useEffect(
    () => setName(props.initialName),
    [props.initialName, props.open]
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (props.open) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [props.open]);
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalDialog sx={{ gap: 2 }}>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <FormControl required>
            <FormLabel>New name</FormLabel>
            <Input
              type="text"
              name="name"
              slotProps={{
                input: {
                  maxLength: nameMaxLength,
                  autoComplete: "name",
                  ref: inputRef,
                },
              }}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          <Button variant="plain" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              api({
                path: "/change-name",
                method: "post",
                body: { name },
                onDone: () => setLoading(false),
                onSuccess: () => {
                  props.onClose();
                  snackbar.success("Name updated");
                },
              });
            }}
          >
            Save
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

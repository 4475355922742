import { Box } from "@mui/joy";

import logo from "../images/logo.svg";
import SignedOutFooter from "../components/SignedOutFooter";

interface Props {
  children: React.ReactNode;
}

export default function AuthenticationLayout(props: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100dvh",
        width: "100%",
        px: 2,
      }}
    >
      <Box sx={{ mx: "auto", py: 2 }}>
        <Box component="img" src={logo} sx={{ height: 96, width: 96 }} />
      </Box>
      <Box
        sx={{
          my: "auto",
          mx: "auto",
          width: 400,
          maxWidth: "100%",
        }}
      >
        {props.children}
      </Box>
      <SignedOutFooter />
    </Box>
  );
}

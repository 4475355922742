import * as React from "react";
import { apiUrl } from "../constants";
import EventEmitter from "events";
import { ChatEvent } from "../interfaces/chats";

interface ChatEvents {
  emitter: EventEmitter;
}

export const ChatEventsContext = React.createContext<ChatEvents>({
  emitter: new EventEmitter(),
});

interface Props {
  chatId: string;
  children: React.ReactNode;
}

export default function ChatEventsProvider(props: Props) {
  const emitter = React.useMemo(() => new EventEmitter(), []);
  const events = React.useMemo(() => ({ emitter }), [emitter]);

  React.useEffect(() => {
    const source = new EventSource(`${apiUrl}/chats/${props.chatId}/events`, {
      withCredentials: true,
    });
    source.onmessage = (event) => {
      const chatEvent: ChatEvent = JSON.parse(event.data);
      emitter.emit(chatEvent.type, chatEvent);
    };
    return () => source.close();
  }, [props.chatId, emitter]);

  return (
    <ChatEventsContext.Provider value={events}>
      {props.children}
    </ChatEventsContext.Provider>
  );
}

import "@fontsource/inter";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SigninPage from "./pages/SigninPage";
import HomePage from "./pages/HomePage";
import SignupPage from "./pages/SignupPage";
import PasswordRecoveryRequestPage from "./pages/PasswordRecoveryRequest";
import theme from "./theme";
import SnackbarProvider from "./providers/SnackbarProvider";
import PasswordRecoveryResetPage from "./pages/PasswordRecoveryReset";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import SessionProvider from "./providers/SessionProvider";
import EventsProvider from "./providers/UserEventsProvider";
import ProfileProvider from "./providers/ProfileProvider";
import DashboardPage from "./pages/DashboardPage";
import ChatPage from "./pages/ChatPage";
import SettingsPage from "./pages/SettingsPage";
import React from "react";
import LoadingPage from "./pages/LoadingPage";
import UpgradePage from "./pages/UpgradePage";
import PricingPage from "./pages/PricingPage";

const AdminApp = React.lazy(() => import("./admin/AdminApp"));
const BlogIndexPage = React.lazy(() => import("./pages/BlogIndexPage"));
const BlogPostPage = React.lazy(() => import("./pages/BlogPostPage"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const TermsOfServicePage = React.lazy(
  () => import("./pages/TermsOfServicePage")
);

export default function App() {
  return (
    <CssVarsProvider theme={theme} defaultColorScheme="dark">
      <CssBaseline />
      <SessionProvider>
        <EventsProvider>
          <ProfileProvider>
            <SnackbarProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/admin/*"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <AdminApp />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/blog"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <BlogIndexPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/blog/page/:page"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <BlogIndexPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/blog/:vanityUrl"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <BlogPostPage />
                      </React.Suspense>
                    }
                  />
                  <Route path="/chats/:chatId" element={<ChatPage />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route
                    path="/email-verification/verify/:token"
                    element={<EmailVerificationPage />}
                  />
                  <Route
                    path="/password-recovery/request"
                    element={<PasswordRecoveryRequestPage />}
                  />
                  <Route
                    path="/password-recovery/reset/:token"
                    element={<PasswordRecoveryResetPage />}
                  />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route
                    path="/privacy-policy"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <PrivacyPolicyPage />
                      </React.Suspense>
                    }
                  />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/signin" element={<SigninPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route
                    path="/terms-of-service"
                    element={
                      <React.Suspense fallback={<LoadingPage />}>
                        <TermsOfServicePage />
                      </React.Suspense>
                    }
                  />
                  <Route path="/upgrade" element={<UpgradePage />} />
                </Routes>
              </BrowserRouter>
            </SnackbarProvider>
          </ProfileProvider>
        </EventsProvider>
      </SessionProvider>
    </CssVarsProvider>
  );
}

import { Box } from "@mui/joy";
import SignedOutHeader from "../components/SignedOutHeader";
import SignedOutFooter from "../components/SignedOutFooter";

interface Props {
  children: React.ReactNode;
}

export default function SignedOutLayout(props: Props) {
  return (
    <Box
      sx={{
        bgcolor: "background.appBody",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SignedOutHeader />
      <Box component="main" sx={{ p: 2, flexGrow: 1, overflowY: "auto" }}>
        {props.children}
        <SignedOutFooter />
      </Box>
    </Box>
  );
}

import { Snackbar } from "@mui/joy";
import * as React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";

const autoHideDurationMs = 3000;

export const SnackbarContext = React.createContext({
  success: (message: string) => {},
  error: (message: string) => {},
});

interface Props {
  children: React.ReactNode;
}

export default function SnackbarProvider(props: Props) {
  // Controls.
  const [open, setOpen] = React.useState(false);
  const [closeAt, setCloseAt] = React.useState(0);

  // Message.
  const [type, setType] = React.useState<"success" | "error">("success");
  const [message, setMessage] = React.useState("");

  // Open and close snackbar based on closeAt.
  React.useEffect(() => {
    if (closeAt <= Date.now()) {
      setOpen(false);
      return;
    }
    setOpen(true);
    const timeoutId = setTimeout(() => setOpen(false), closeAt - Date.now());
    return () => clearTimeout(timeoutId);
  }, [closeAt]);

  // Cache context.
  const context = React.useMemo(() => {
    const handle = (type: "success" | "error", message: string) => {
      setCloseAt(Date.now() + autoHideDurationMs);
      setType(type);
      setMessage(message);
    };
    return {
      success: (message: string) => handle("success", message),
      error: (message: string) => handle("error", message),
    };
  }, []);

  return (
    <>
      <SnackbarContext.Provider value={context}>
        {props.children}
      </SnackbarContext.Provider>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setCloseAt(0)}
        color={type === "error" ? "danger" : "success"}
        startDecorator={type === "error" ? <ErrorIcon /> : <DoneIcon />}
      >
        {message}
      </Snackbar>
    </>
  );
}

import { Button, Card, CardActions, CardContent, Typography } from "@mui/joy";
import EmailVerificationModal from "../modals/EmailVerificationModal";
import React from "react";
import useProfile from "../hooks/useProfile";

export default function EmailVerificationCard() {
  const [open, setOpen] = React.useState(false);
  const profile = useProfile();
  return (
    <>
      <Card orientation="vertical" variant="outlined">
        <CardContent>
          <Typography level="title-lg">Verify your Email Address ✉️</Typography>
          <Typography level="body-sm">
            Please verify your email address first. Once you do, you'll be able
            to start your first session with Christina.
          </Typography>
        </CardContent>
        <CardActions orientation="vertical">
          <Button
            variant="solid"
            size="md"
            color="primary"
            sx={{ alignSelf: "center" }}
            onClick={() => setOpen(true)}
          >
            Verify my email
          </Button>
        </CardActions>
      </Card>
      <EmailVerificationModal
        email={profile?.email ?? ""}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/joy";
import SignedOutLayout from "../layouts/SignedOutLayout";
import logo from "../images/logo.svg";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";

export default function HomePage() {
  const session = useSession();
  return (
    <SignedOutLayout>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{ mt: 10, alignSelf: "center", height: 120, width: 120 }}
          component="img"
          src={logo}
        />
        <Typography sx={{ mt: 5 }} textAlign="center" level="h1">
          AI-Powered Therapy at Your Fingertips
        </Typography>
        <Button
          component={RouterLink}
          size="lg"
          sx={{ alignSelf: "center", px: 6, py: 2, mt: 5 }}
          to={session.userId === null ? "/signup" : "/dashboard"}
        >
          {session.userId === null ? "Start Now for Free" : "Start Now"}
        </Button>
        <Typography level="body-lg" sx={{ mt: 10 }}>
          Christina.app offers cognitive behavioral therapy through an
          AI-powered virtual therapist. Enjoy confidential, professional
          guidance on your schedule, at a fraction of the cost of traditional
          therapy. Get help with anxiety, mood disorders, life issues, and more,
          without the stigma of seeing a human therapist.
        </Typography>
        <Grid container spacing={2} sx={{ mt: 10 }}>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">✨ Expert-Enhanced AI</Typography>
                <Typography level="body-lg">
                  Our AI therapist is constantly monitored and improved by real
                  professionals. Summaries of sessions are reviewed by licensed
                  therapists who provide feedback, making the AI smarter and
                  more effective over time.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">
                  🌍 Therapy for Everyone
                </Typography>
                <Typography level="body-lg">
                  Christina.app makes therapy accessible to all by significantly
                  reducing costs. No need to pay for expensive 1:1 sessions—get
                  the help you need at a fraction of the cost.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">
                  ⏰ Help When You Need It
                </Typography>
                <Typography level="body-lg">
                  Life doesn't wait for office hours. With Christina.app, you
                  can have a therapeutic conversation whenever you need it, day
                  or night, from the comfort of your own space.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">🌪 Tackle Any Challenge</Typography>
                <Typography level="body-lg">
                  Whether it's your mood, anxiety, work stress, or relationship
                  problems, Christina.app is equipped to help you navigate and
                  overcome a wide range of emotional challenges.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">
                  👍 Always Getting Better
                </Typography>
                <Typography level="body-lg">
                  Christina.app isn't static. Our AI therapist continuously
                  learns and improves with your feedback, ensuring you receive
                  the most effective support possible with each session.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft">
              <CardContent>
                <Typography level="title-lg">🕵️ Talk Without Fear</Typography>
                <Typography level="body-lg">
                  No need to feel embarrassed or judged. Christina.app ensures
                  complete confidentiality by using AI for therapy, so your
                  secrets stay safe with the machine.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </SignedOutLayout>
  );
}

import React from "react";
import {
  stripeDarkPricingTableId,
  stripeLightPricingTableId,
  stripePublishableKey,
} from "../constants";
import { useColorScheme } from "@mui/joy";

interface Props {
  clientSecret: string;
}

export default function StripePricingTable(props: Props) {
  const { mode } = useColorScheme();
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id":
      mode === "light" ? stripeLightPricingTableId : stripeDarkPricingTableId,
    "publishable-key": stripePublishableKey,
    "customer-session-client-secret": props.clientSecret,
  });
}

import { Box } from "@mui/joy";
import { Dashboard, Settings } from "@mui/icons-material";
import FooterMenuItem from "./FooterMenuItem";

export default function SignedInFooter() {
  return (
    <Box
      sx={{
        bgcolor: "background.surface",
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <FooterMenuItem icon={<Dashboard />} name="Dashboard" path="/dashboard" />
      <FooterMenuItem icon={<Settings />} name="Settings" path="/settings" />
    </Box>
  );
}

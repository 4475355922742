import React from "react";
import { ProfileResponse } from "../interfaces/profile";
import useGetApi from "../hooks/useGetApi";
import { UserEventProfileChange } from "../interfaces/users";
import useUserEvent from "../hooks/useUserEvent";

export const ProfileContext = React.createContext<null | ProfileResponse>(null);

interface Props {
  children: React.ReactNode;
}

export default function ProfileProvider(props: Props) {
  const [profile, setProfile] = React.useState<ProfileResponse | null>(null);
  const response = useGetApi<ProfileResponse>("/profile");
  React.useEffect(() => {
    if (response.status === "success") {
      setProfile(response.response);
    }
  }, [response]);
  const onProfileChange = React.useCallback((event: UserEventProfileChange) => {
    setProfile(event.profile);
  }, []);
  useUserEvent("profileChange", onProfileChange);

  return (
    <ProfileContext.Provider value={profile}>
      {props.children}
    </ProfileContext.Provider>
  );
}

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
} from "@mui/joy";
import useApi from "../hooks/useApi";
import React from "react";
import useSnackbar from "../hooks/useSnackbar";
import { passwordMaxLength } from "../constants";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function PasswordChangeModal(props: Props) {
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [repeatNewPassword, setRepeatNewPassword] = React.useState("");

  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  React.useEffect(() => {
    setPassword("");
    setNewPassword("");
    setRepeatNewPassword("");
  }, [props.open]);
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalDialog sx={{ gap: 2 }}>
        <DialogTitle>Edit Password</DialogTitle>
        <DialogContent sx={{ gap: 2 }}>
          <FormControl required>
            <FormLabel>Current password</FormLabel>
            <Input
              type="password"
              name="password"
              slotProps={{
                input: {
                  maxLength: passwordMaxLength,
                  autoComplete: "current-password",
                },
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormControl>
          <FormControl required>
            <FormLabel>New password</FormLabel>
            <Input
              type="password"
              name="new-password"
              slotProps={{
                input: {
                  maxLength: passwordMaxLength,
                  autoComplete: "new-password",
                },
              }}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </FormControl>
          <FormControl
            required
            color={newPassword !== repeatNewPassword ? "danger" : undefined}
          >
            <FormLabel>Repeat new password</FormLabel>
            <Input
              type="password"
              name="repeat-new-password"
              slotProps={{
                input: {
                  maxLength: passwordMaxLength,
                  autoComplete: "new-password",
                },
              }}
              value={repeatNewPassword}
              onChange={(event) => setRepeatNewPassword(event.target.value)}
            />
            {newPassword !== repeatNewPassword && (
              <FormHelperText>Passwords do not match.</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          <Button variant="plain" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              api({
                path: "/change-password",
                method: "post",
                body: { password, newPassword },
                onDone: () => setLoading(false),
                onSuccess: () => {
                  props.onClose();
                  snackbar.success("Password updated");
                },
              });
            }}
            disabled={newPassword !== repeatNewPassword}
          >
            Save
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

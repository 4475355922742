import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";

import AuthenticationLayout from "../layouts/AuthenticationLayout";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../hooks/useApi";
import useTitle from "../hooks/useTitle";

export default function PasswordRecoveryRequestPage() {
  useTitle("Password Recovery");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const snackbar = useSnackbar();
  const api = useApi();

  return (
    <AuthenticationLayout>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            Password Recovery
          </Typography>
          {!success && (
            <Typography level="body-sm">
              We'll send you an email with instructions.
            </Typography>
          )}
          {success && (
            <Typography level="body-md">
              Please check your inbox. We've sent you an email with instructions
              to recover access to your account.
            </Typography>
          )}
        </Stack>
        {!success && (
          <>
            <FormControl required>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                slotProps={{
                  input: {
                    autoComplete: "email",
                  },
                }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              loading={loading}
              onClick={() => {
                setLoading(true);
                api({
                  path: "/password-recovery/request",
                  method: "post",
                  body: {
                    email,
                  },
                  onDone: () => setLoading(false),
                  onSuccess: () => {
                    snackbar.success("Email sent.");
                    setSuccess(true);
                  },
                });
              }}
            >
              Recover Password
            </Button>
          </>
        )}
      </Stack>
    </AuthenticationLayout>
  );
}

import nullthrows from "nullthrows";

export const apiUrl = nullthrows(process.env.REACT_APP_API_URL);
export const blogPostBodyMaxLength = 100000; // 100k
export const blogPostTitleMaxLength = 1000;
export const chatGracePeriodMs = 5 * 60 * 1000; // 5 minutes
export const messageContentMaxLength = 1000;
export const nameMaxLength = 50;
export const passwordMaxLength = 64;
export const stripeLightPricingTableId = nullthrows(
  process.env.REACT_APP_STRIPE_LIGHT_PRICING_TABLE_ID
);
export const stripeDarkPricingTableId = nullthrows(
  process.env.REACT_APP_STRIPE_DARK_PRICING_TABLE_ID
);
export const stripePublishableKey = nullthrows(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);
export const vanityUrlMaxLength = 100;

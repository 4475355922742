import * as React from "react";
import useSession from "../hooks/useSession";
import { apiUrl } from "../constants";
import EventEmitter from "events";
import { UserEvent } from "../interfaces/users";

interface Events {
  emitter: EventEmitter;
}

export const UserEventsContext = React.createContext<Events>({
  emitter: new EventEmitter(),
});

interface Props {
  children: React.ReactNode;
}

export default function UserEventsProvider(props: Props) {
  const session = useSession();
  const { userId } = session;

  const emitter = React.useMemo(() => new EventEmitter(), []);
  const events = React.useMemo(() => ({ emitter }), [emitter]);

  React.useEffect(() => {
    if (userId === null) {
      return;
    }
    const source = new EventSource(`${apiUrl}/users/${userId}/events`, {
      withCredentials: true,
    });
    source.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      emitter.emit(type, data);
    };
    source.onmessage = (event) => {
      const userEvent: UserEvent = JSON.parse(event.data);
      emitter.emit(userEvent.type, userEvent);
    };
    return () => source.close();
  }, [userId, emitter]);

  return (
    <UserEventsContext.Provider value={events}>
      {props.children}
    </UserEventsContext.Provider>
  );
}

import React from "react";
import { ChatResponse } from "../interfaces/chats";
import dayjs from "../utils/dayjs";
import useRefresh from "../hooks/useRefresh";
import { Chip } from "@mui/joy";

interface Props {
  chat: ChatResponse;
}

interface StateNone {
  status: "none";
}

interface StateEnding {
  status: "ending";
  timeLeft: string;
}

interface StateEnded {
  status: "ended";
  timeAgo: string;
}

type State = StateNone | StateEnding | StateEnded;

const endingMs = 5 * 60 * 1000; // 5 minutes

export default function ChatWarning(props: Props) {
  const stateFn = React.useCallback<() => State>(() => {
    const now = dayjs();
    const createdAt = dayjs(props.chat.createdAt);
    const endsAt = createdAt.add(props.chat.durationMs, "ms");
    const leftMs = endsAt.diff(now, "ms");
    if (leftMs <= 0) {
      return {
        status: "ended",
        timeAgo: endsAt.from(now),
      };
    }
    if (leftMs <= endingMs) {
      return {
        status: "ending",
        timeLeft: now.to(endsAt),
      };
    }
    return {
      status: "none",
    };
  }, [props.chat]);
  const state = useRefresh(stateFn, 1000);

  if (state.status === "ending") {
    return (
      <Chip color="warning" variant="soft">
        Session ends {state.timeLeft}
      </Chip>
    );
  }

  if (state.status === "ended") {
    return (
      <Chip color="danger" variant="soft">
        Session ended {state.timeAgo}
      </Chip>
    );
  }

  return null;
}

import { Warning } from "@mui/icons-material";
import { Alert, Box, Button, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";

interface Props {
  error: string;
}

export default function ErrorPage(props: Props) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Alert
        sx={{ alignSelf: "stretch", flexDirection: "column" }}
        color="danger"
      >
        <Typography color="danger" level="h1">
          <Warning />
        </Typography>
        <Typography>{props.error}</Typography>
      </Alert>
      <Button sx={{ mt: 4 }} onClick={() => navigate("/")}>
        Go home
      </Button>
    </Box>
  );
}

import { Key } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";

export default function SettingsAdminRow() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography level="title-md">Admin Panel</Typography>
      </Box>
      <Box>
        <Button
          sx={{ alignSelf: "center" }}
          color="neutral"
          startDecorator={<Key />}
          onClick={() => navigate("/admin")}
        >
          Admin Panel
        </Button>
      </Box>
    </Box>
  );
}

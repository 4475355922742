import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import nullthrows from "nullthrows";

import { passwordMaxLength } from "../constants";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../hooks/useApi";
import useTitle from "../hooks/useTitle";

export default function PasswordRecoveryResetPage() {
  useTitle("Password Reset");
  const params = useParams();
  const token = nullthrows(params.token);
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const api = useApi();

  return (
    <AuthenticationLayout>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            Password Reset
          </Typography>
          <Typography level="body-sm">
            Set up a new password for your account.
          </Typography>
        </Stack>
        <FormControl required>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            name="password"
            slotProps={{
              input: {
                maxLength: passwordMaxLength,
                autoComplete: "new-password",
              },
            }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          loading={loading}
          onClick={() => {
            setLoading(true);
            api({
              path: "/password-recovery/reset",
              method: "post",
              body: {
                token,
                password,
              },
              onDone: () => setLoading(false),
              onSuccess: () => {
                snackbar.success("New password saved.");
                navigate("/");
              },
            });
          }}
        >
          Reset password
        </Button>
      </Stack>
    </AuthenticationLayout>
  );
}

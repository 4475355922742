import { Send as SendIcon } from "@mui/icons-material";
import { Box, IconButton, Textarea } from "@mui/joy";
import React from "react";
import useApi from "../hooks/useApi";
import { chatGracePeriodMs, messageContentMaxLength } from "../constants";
import { ChatResponse } from "../interfaces/chats";
import dayjs from "../utils/dayjs";
import useChatEvent from "../hooks/useChatEvent";

interface Props {
  chat: ChatResponse;
}

export default function ChatComposer(props: Props) {
  const api = useApi();
  const [content, setContent] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const endedFn = React.useCallback(
    () =>
      dayjs().isAfter(
        dayjs(props.chat.createdAt).add(props.chat.durationMs, "ms")
      ),
    [props.chat.createdAt, props.chat.durationMs]
  );

  // Handle ended status.
  const [ended, setEnded] = React.useState(endedFn);
  React.useEffect(() => {
    const ended = endedFn();
    setEnded(ended);
    if (!ended) {
      const timeoutMs = dayjs(props.chat.createdAt)
        .add(props.chat.durationMs + chatGracePeriodMs, "ms")
        .diff(dayjs(), "ms");
      const timeoutId = setTimeout(() => setEnded(true), timeoutMs);
      return () => clearTimeout(timeoutId);
    }
  }, [endedFn, props.chat.createdAt, props.chat.durationMs]);
  const handleEnd = React.useCallback(() => setEnded(true), []);
  useChatEvent("end", handleEnd);

  const sendMessage = () => {
    if (loading || content.length === 0) {
      return;
    }
    setContent("");
    setLoading(true);
    api({
      path: `/chats/${props.chat.chatId}/messages`,
      method: "POST",
      body: {
        content,
      },
      onDone: () => setLoading(false),
    });
    textareaRef.current?.focus();
  };

  return (
    <Box
      sx={{
        bgcolor: "background.surface",
      }}
    >
      <Box
        sx={{
          mx: "auto",
          maxWidth: "800px",
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: 1,
        }}
      >
        <Textarea
          size="lg"
          sx={{ flexGrow: 1 }}
          placeholder="Type a message"
          minRows={1}
          maxRows={10}
          value={content}
          onChange={(event) => setContent(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              sendMessage();
              event.preventDefault();
            }
          }}
          autoFocus
          slotProps={{
            textarea: { ref: textareaRef, maxLength: messageContentMaxLength },
          }}
          disabled={ended}
        />
        <IconButton
          size="lg"
          onClick={sendMessage}
          disabled={content.length === 0 || ended}
          loading={loading}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

import React from "react";
import useApi from "./useApi";
import useSession from "./useSession";

interface Loading {
  status: "loading";
}

interface Success<Response> {
  status: "success";
  response: Response;
}

interface Error {
  status: "error";
  error: string;
}

type Result<Response> = Loading | Success<Response> | Error;

export default function useGetApi<Response>(path: string): Result<Response> {
  const session = useSession();
  const { userId } = session;
  const api = useApi();
  const [result, setResult] = React.useState<Result<Response>>({
    status: "loading",
  });
  React.useEffect(() => {
    // TODO: cancel ongoing fetch if path or userId changes.
    let active = true;
    setResult({ status: "loading" });
    api({
      path,
      method: "GET",
      onSuccess: (response) => {
        if (active) {
          setResult({ status: "success", response });
        }
      },
      onError: (error) => {
        // Non-retryable error.
        if (active) {
          setResult({ status: "error", error: error.toString() });
        }
      },
      retry: true,
    });
    return () => {
      active = false;
    };
  }, [api, path, userId]);
  return result;
}

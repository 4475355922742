import React from "react";
import useChatEvents from "./useChatEvents";

export default function useChatEvent(
  type: string,
  listener: (data: any) => void
) {
  const events = useChatEvents();
  React.useEffect(() => {
    events.emitter.on(type, listener);
    return () => {
      events.emitter.off(type, listener);
    };
  }, [events, type, listener]);
}

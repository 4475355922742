import { Button, Card, CardActions, CardContent, Typography } from "@mui/joy";
import useApi from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { CreateChatResponse } from "../interfaces/chats";
import React from "react";
import { AgentResponse } from "../interfaces/agents";
import dayjs from "../utils/dayjs";
import useRefresh from "../hooks/useRefresh";
import useProfile from "../hooks/useProfile";

interface Props {
  agent: AgentResponse;
}

interface StateOngoing {
  status: "ongoing";
  timeLeft: string;
  chatId: string;
}

interface StateReady {
  status: "ready";
  durationMinutes: number;
}

interface StateWait {
  status: "wait";
  timeToNext: string;
}

type State = StateOngoing | StateReady | StateWait;

export default function AgentCard(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const profile = useProfile();

  const stateFn = React.useCallback<() => State>(() => {
    const now = dayjs();
    if (props.agent.activeChat !== null) {
      const createdAt = dayjs(props.agent.activeChat.createdAt);
      const endsAt = createdAt.add(props.agent.activeChat.durationMs, "ms");
      const leftMs = endsAt.diff(now);
      if (leftMs > 0) {
        return {
          status: "ongoing",
          timeLeft: dayjs(now).to(endsAt, true),
          chatId: props.agent.activeChat.chatId,
        };
      }
    }
    const nextBeginAt = dayjs(props.agent.nextBeginAt);
    const waitMs = nextBeginAt.diff(now);
    if (waitMs > 0) {
      return {
        status: "wait",
        timeToNext: dayjs(now).to(nextBeginAt),
      };
    }
    return {
      status: "ready",
      durationMinutes: Math.round(props.agent.durationMs / 60 / 1000),
    };
  }, [props.agent]);
  const state = useRefresh(stateFn, 1000);

  return (
    <Card orientation="vertical" variant="outlined">
      <CardContent>
        <Typography level="title-lg">{props.agent.title}</Typography>
        <Typography level="body-sm">{props.agent.description}</Typography>
      </CardContent>
      <CardActions orientation="vertical">
        {state.status === "ongoing" && (
          <Button
            variant="solid"
            size="md"
            color="success"
            onClick={() => navigate(`/chats/${state.chatId}`)}
            sx={{ alignSelf: "center" }}
          >
            Continue Session ({state.timeLeft} left)
          </Button>
        )}
        {state.status === "ready" && (
          <>
            <Button
              variant="solid"
              size="md"
              color="primary"
              sx={{ alignSelf: "center" }}
              onClick={() => {
                api({
                  path: "/chats",
                  method: "POST",
                  body: {
                    agent: props.agent.name,
                  },
                  onSuccess: (response: CreateChatResponse) => {
                    navigate(`/chats/${response.chatId}`);
                  },
                  onDone: () => setLoading(false),
                });
              }}
              loading={loading}
              disabled={profile?.emailVerified === false}
            >
              Start {state.durationMinutes}-minute Session
            </Button>
            {profile?.emailVerified === false && (
              <Typography
                level="body-sm"
                sx={{ alignSelf: "center" }}
                color="danger"
              >
                You must verify your email first
              </Typography>
            )}
          </>
        )}
        {state.status === "wait" && (
          <Button
            variant="solid"
            size="md"
            color="neutral"
            onClick={() => {}}
            sx={{ alignSelf: "center" }}
          >
            Start Session {state.timeToNext}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

import { Box, Button } from "@mui/joy";
import ColorSchemeToggle from "./ColorSchemeToggle";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";
import logo from "../images/logo.svg";

export default function SignedOutHeader() {
  const session = useSession();
  return (
    <Box
      sx={{
        bgcolor: "background.surface",
        p: 1,
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box component="img" src={logo} sx={{ height: 40, width: 40 }} />
      <Box
        sx={{
          flexGrow: 1,
        }}
      ></Box>
      <ColorSchemeToggle />
      {session.userId === null && (
        <Button component={RouterLink} variant="plain" to="/signin">
          Sign In
        </Button>
      )}
      {session.userId === null && (
        <Button component={RouterLink} to="/signup">
          Sign Up
        </Button>
      )}
      {session.userId !== null && (
        <Button component={RouterLink} to="/dashboard">
          Go to Dashboard
        </Button>
      )}
    </Box>
  );
}

import { Box, Link } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "./Copyright";

export default function SignedOutFooter() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
          mt: 10,
        }}
      >
        <Link component={RouterLink} to="/">
          Home
        </Link>
        <Link component={RouterLink} to="/pricing">
          Pricing
        </Link>
        <Link component={RouterLink} to="/blog">
          Blog
        </Link>
        <Link component={RouterLink} to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link component={RouterLink} to="/terms-of-service">
          Terms of Service
        </Link>
      </Box>
      <Copyright />
    </Box>
  );
}

import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import useProfile from "../hooks/useProfile";
import React from "react";
import NameChangeModal from "../modals/NameChangeModal";

export default function SettingsNameRow() {
  const profile = useProfile();
  const [open, setOpen] = React.useState(false);
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography level="title-md">Name</Typography>
        <Typography level="body-sm" noWrap>
          {profile?.name}
        </Typography>
      </Box>
      <Box>
        {profile !== null && (
          <>
            <IconButton variant="soft" onClick={() => setOpen(true)}>
              <Edit />
            </IconButton>
            <NameChangeModal
              initialName={profile.name}
              open={open}
              onClose={() => setOpen(false)}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

import { Upgrade } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import useProfile from "../hooks/useProfile";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import React from "react";
import { StripeBillingPortalResponse } from "../interfaces/stripe";

const tiers = {
  free: {
    title: "🆓 Free account",
    description: "Limited to one 10-minute session every day.",
    upgradeable: true,
  },
  premium: {
    title: "✨ Premium account",
    description: "Unlimited access to 30-minute sessions.",
    upgradeable: false,
  },
};

export default function SettingsTierRow() {
  const navigate = useNavigate();
  const profile = useProfile();
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  if (profile === null) {
    return null;
  }
  const tier = tiers[profile.tier];
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography level="title-md">{tier.title}</Typography>
        <Typography level="body-sm">{tier.description}</Typography>
      </Box>
      {tier.upgradeable && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="success"
            variant="soft"
            startDecorator={<Upgrade />}
            onClick={() => navigate("/upgrade")}
          >
            Upgrade
          </Button>
        </Box>
      )}
      {!tier.upgradeable && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="neutral"
            variant="soft"
            onClick={() => {
              api({
                method: "POST",
                path: "/stripe/billing-portal",
                onSuccess: (response: StripeBillingPortalResponse) => {
                  window.location.href = response.url;
                },
                onDone: () => setLoading(false),
              });
            }}
            loading={loading}
          >
            Manage subscription
          </Button>
        </Box>
      )}
    </Box>
  );
}

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import SignedOutLayout from "../layouts/SignedOutLayout";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";
import useTitle from "../hooks/useTitle";
import useProfile from "../hooks/useProfile";

export default function PricingPage() {
  useTitle("Pricing");
  const session = useSession();
  const profile = useProfile();
  return (
    <SignedOutLayout>
      <Typography level="h1" textAlign="center" gutterBottom>
        Pricing
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", alignItems: "stretch" }}
      >
        <Grid xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography level="h2" textAlign="center">
                  🆓 Free
                </Typography>
                <Typography level="h3" textAlign="center">
                  $0 forever
                </Typography>
                <Typography level="body-md">
                  We believe everyone should have access to therapy, regardless
                  of financial circumstances. Perfect for those starting their
                  mental health journey.
                </Typography>
                <List sx={{ alignSelf: "center" }}>
                  <ListItem>
                    <ListItemDecorator>📅</ListItemDecorator> 1 session / day
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>🕒</ListItemDecorator> 10 minutes /
                    session
                  </ListItem>
                </List>
              </Box>
            </CardContent>
            <CardActions orientation="vertical">
              {session.userId === null && (
                <Button
                  component={RouterLink}
                  size="lg"
                  sx={{ alignSelf: "center" }}
                  to="/signup"
                >
                  Sign Up
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography level="h2" textAlign="center">
                  ✨ Premium
                </Typography>
                <Typography level="h3" textAlign="center">
                  $20 / month
                </Typography>
                <Typography level="body-md">
                  For those seeking deeper, more comprehensive support. Ideal
                  for individuals committed to long-term mental wellness.
                </Typography>
                <List sx={{ alignSelf: "center" }}>
                  <ListItem>
                    <ListItemDecorator>♾️</ListItemDecorator> Unlimited sessions
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>🕒</ListItemDecorator> 30 minutes /
                    session
                  </ListItem>
                </List>
              </Box>
            </CardContent>
            <CardActions orientation="vertical">
              {session.userId === null && (
                <Button
                  component={RouterLink}
                  size="lg"
                  sx={{ alignSelf: "center" }}
                  to="/signup"
                >
                  Sign Up
                </Button>
              )}
              {profile?.tier === "free" && (
                <Button
                  component={RouterLink}
                  size="lg"
                  sx={{ alignSelf: "center" }}
                  to="/upgrade"
                >
                  Upgrade Now
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </SignedOutLayout>
  );
}

import React from "react";
import useSnackbar from "./useSnackbar";
import { apiUrl } from "../constants";

const retryWaitMs = 3000;

interface FetchParams {
  path: string;
  method: string;
  body?: any;
  onDone?: () => void;
  onSuccess?: (response: any) => void;
  onError?: (error: string) => void;
  onAbort?: () => void;
  retry?: boolean;
}

export default function useApi() {
  const snackbar = useSnackbar();
  const abort = React.useRef<AbortController>();
  React.useEffect(() => {
    abort.current = new AbortController();
    return () => {
      abort.current?.abort("unmounted");
      abort.current = undefined;
    };
  }, []);
  const api = React.useCallback(
    (params: FetchParams) => {
      const currentAbort = abort.current;

      // Fetch without retry.
      const doFetch = () =>
        fetch(`${apiUrl}${params.path}`, {
          method: params.method,
          headers: {
            Accept: "application/json",
            ...(params.body instanceof FormData
              ? {}
              : { "Content-Type": "application/json" }),
          },
          body:
            params.body instanceof FormData
              ? params.body
              : JSON.stringify(params.body),
          credentials: "include",
          signal: currentAbort?.signal,
        });

      // Fetch with retries when applicable.
      const doFetchWithRetry = async () => {
        if (!params.retry) {
          // Retries are not enabled. Do not catch exceptions.
          return await doFetch();
        }

        // Retries are enabled. Catch exceptions and retry in a loop.
        while (true) {
          try {
            // Attempt to fetch.
            return await doFetch();
          } catch {
            // An exception occurred.
            if (currentAbort?.signal.aborted) {
              throw new Error("aborted");
            }
            // Wait for some time before retrying.
            await new Promise((resolve) => setTimeout(resolve, retryWaitMs));
            if (currentAbort?.signal.aborted) {
              throw new Error("aborted");
            }
          }
        }
      };

      (async () => {
        // Fetch.
        const response = await doFetchWithRetry();

        // Parse JSON response.
        const json = await response.json();
        if (currentAbort?.signal.aborted) {
          params.onAbort?.();
          return;
        }

        // Success.
        if (response.ok) {
          params.onDone?.();
          params.onSuccess?.(json);
          return;
        }

        // Error.
        const error = json.error.toString();
        params.onDone?.();
        params.onError?.(error);
        snackbar.error(error);
      })().catch(() => {
        if (currentAbort?.signal.aborted) {
          params.onAbort?.();
          return;
        }
        params.onDone?.();
        const error = "Unexpected error. Please try again.";
        params.onError?.(error);
        snackbar.error(error);
      });
    },
    [snackbar]
  );
  return api;
}

import {
  DarkModeRounded as DarkModeRoundedIcon,
  LightModeRounded as LightModeRoundedIcon,
} from "@mui/icons-material";
import { IconButton, Tooltip, useColorScheme } from "@mui/joy";

export default function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        size="md"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: "center" }}
        onClick={() => {
          if (mode === "light") {
            setMode("dark");
          } else {
            setMode("light");
          }
        }}
      >
        {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

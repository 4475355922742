import React from "react";
import { Button, Stack, Typography } from "@mui/joy";

import AuthenticationLayout from "../layouts/AuthenticationLayout";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import nullthrows from "nullthrows";
import useTitle from "../hooks/useTitle";

export default function EmailVerificationPage() {
  useTitle("Email Verification");
  const params = useParams();
  const token = nullthrows(params.token);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const snackbar = useSnackbar();
  const api = useApi();
  const navigate = useNavigate();

  React.useEffect(() => {
    setSuccess(false);
    setError(null);
    setLoading(true);
    api({
      path: "/email-verification/verify",
      method: "post",
      body: { token },
      onDone: () => setLoading(false),
      onSuccess: () => {
        setSuccess(true);
        snackbar.success("Email verified.");
      },
      onError: setError,
    });
  }, [api, snackbar, token]);

  return (
    <AuthenticationLayout>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {loading && "Verifying your email..."}
            {success && "✔️ Email Verified"}
            {error !== null && "❌ Email Verification Failed"}
          </Typography>
          {error && <Typography>{error}</Typography>}
        </Stack>
        {success && (
          <Button type="submit" fullWidth onClick={() => navigate("/")}>
            Go home
          </Button>
        )}
      </Stack>
    </AuthenticationLayout>
  );
}
